<template>
    
    <div>
        <div class="content-serve" id="mainId">
            <div class="serve-head motion-serve" :class="scroll ? 'scroll-visible' : 'scroll-hidden'">
                <h1>服务范围</h1>
            </div>

            <div class="serve-list">
                <div class="serve-item">
                    <a href="#">
                        <div class="imgText">
                            <div class="iconbg">
                                <i class="iconfont icon-jixieshebei" ></i>
                            </div>
                            <p>物联网技术</p>
                        </div>
                        <div class="text">
                            <p>物联网设备制造</p>
                            <p>物联网应用服务</p>
                            <p>各种物联网相关科技研发</p>
                        </div>
                    </a>
                </div>
                <div class="serve-item">
                    <a href="#">
                        <div class="imgText">
                            <div class="iconbg">
                                <i class="iconfont icon-cplus" ></i>
                            </div>
                            <p>软件技术开发</p>
                        </div>
                        <div class="text">
                            <p>手机APP</p>
                            <p>微信小程序</p>
                            <p>线上管理系统</p>
                        </div>
                    </a>
                </div>
                <div class="serve-item">
                    <a href="#">
                        <div class="imgText">
                            <div class="iconbg">
                                <i class="iconfont icon-zixun" ></i>
                            </div>
                            <p>技术咨询</p>
                        </div>
                        <div class="text">
                            <p>电子硬件设备咨询</p>
                            <p>软件相关咨询</p>
                            <p>绩效管理咨询</p>
                        </div>

                    </a>
                </div>
                <div class="serve-item">
                    <a href="#">
                        <div class="imgText">
                            <div class="iconbg">
                                <i class="iconfont icon-fuwu" ></i>
                            </div>
                            <p>技术服务</p>
                        </div>
                        <div class="text">
                            <p>软硬件技术服务</p>
                            <p>开发服务</p>
                            <p>售后服务</p>
                        </div>
                    </a>
                </div>
                <div style="clear:both;"></div>
            </div>
            <div class="serve-list two">
                <div class="serve-item">
                    <a href="#">
                        <div class="imgText">
                            <div class="iconbg">
                                <i class="iconfont icon-zhuanrangzhuangtai" ></i>
                            </div>
                            <p>技术转让及销售</p>
                        </div>
                        <div class="text">
                            <p>技术转让</p>
                            <p>技术销售</p>
                        </div>
                    </a>
                </div>
                <div class="serve-item">
                    <a href="#">
                        <div class="imgText">
                            <div class="iconbg">
                                <i class="iconfont icon-fuwu1" ></i>
                            </div>
                            <p>计算机软硬件</p>
                        </div>
                        <div class="text">
                            <p>硬件定制开发</p>
                            <p>辅助设备零售</p>
                            <p>辅助设备批发</p>
                        </div>
                    </a>
                </div>
                <div class="serve-item">
                    <a href="#">
                        <div class="imgText">
                            <div class="iconbg">
                                <i class="iconfont icon-maoyijinchukoulingshoupifa" ></i>
                            </div>
                            <p>数字文化创意服务</p>
                        </div>
                        <div class="text">
                            <p>网店设计</p>
                            <p>广告设计</p>
                            <p>广告代理</p>
                        </div>

                    </a>
                </div>
                <div class="serve-item">
                    <a href="#">
                        <div class="imgText">
                            <div class="iconbg">
                                <i class="iconfont icon-fuwu" ></i>
                            </div>
                            <p>互联网安全服务</p>
                        </div>
                        <div class="text">
                            <p>网站安全等保</p>
                            <p>网站安全升级</p>
                            <p>网站安全检测</p>
                        </div>
                    </a>
                </div>
                <div style="clear:both;"></div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    data(){
        return{
            scroll: false,
        }
    },
    mounted() {
        const that = this
        const mainId = $("#mainId");
        const cultureId = $("#cultureId");
        const top = mainId.offset().top;
        const cultureIdTop = cultureId.offset().top;
        window.addEventListener('scroll', function() {
            if ((window.pageYOffset > top || window.innerHeight >= top) && window.pageYOffset < cultureIdTop) {
                that.scroll = true
            } else {
                that.scroll = false
            }
        })
    },

}
</script>



<style scoped>
*{
  margin: 0;
  padding: 0;
}
.content-serve .serve-head {
    margin-bottom: 50px;
    text-align: center;
    margin-top: 50px;
}
.scroll-hidden{
    opacity: 0!important;
    transform: translateY(50px);
}
.scroll-visible {
    opacity: 1;
    transform: translateY(0);
}
.motion-serve {
    transition: all 1s;
}
.serve-list {
    width: 1100px;
    margin: 20px auto;
}
.serve-list .serve-item:first-child {
    margin-left: 0;
    animation: one .2s linear;
}

.serve-list .serve-item {
    width: 258px;
    height: 249px;
    float: left;
    border: 1px solid #E9EBEF;
    background: #FFF;
    border-radius: 12px;
    margin-left: 20px;
}
.serve-list .serve-item a {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: #252525;
    text-decoration: none;
    text-align: center;
}
.serve-list .serve-item a .imgText {
    width: 100%;
    height: 93px;
    font-size: 21px;
    letter-spacing: .76px;
    margin: 32px 0 16px;
}
.imgText .iconbg{
    width: 60px;
    height: 60px;
    background-image: linear-gradient(to bottom right, #7aefd5,#52afec, #2835ba);
    border-radius: 50%;
    text-align: center;
    margin: 20px 100px;
}
.imgText .iconbg i{
    display: block;
    font-size: 34px;
    padding-top: 13px;
    color: white;
}
.serve-list .serve-item a .imgText p {
    font-size: 21px;
    color: #252525;
    letter-spacing: .76px;
    line-height: 21px;
    font-weight: 500;
}
.serve-item:hover{
    margin-top:0;
    box-shadow:0 0 20px 2px #d9ecf3;
    transition:all .5s;
}
@keyframes one{
    from{
        opacity:0;
        transform:translateY(20px);
    }
    to{
        opacity:1;
        transform:translateY(0)
    }
}
@keyframes two{
    from{
        opacity:0;
        transform:translateY(30px);
    }
    to{
        opacity:1;
        transform:translateY(0);
    }
}
@keyframes three{
    from{
        opacity:0;
        transform:translateY(60px);
    }
    to{
        opacity:1;
        transform:translateY(0);
    }
}
@keyframes four{
    from{
        opacity:0;
        transform:translateY(90px);
    }
    to{
        opacity:1;
        transform:translateY(0);
    }
}
.serve-list .serve-item:first-child{
    margin-left:0;
    animation:one .2s linear;
}
.serve-list .serve-item:nth-child(2){
    animation:two .3s linear;
}
.serve-list .serve-item:nth-child(3){
    animation:three .5s linear;
}
.serve-list .serve-item:nth-child(4){
    animation:four .7s linear;
}
.serve-list.two .serve-item:first-child{
    animation:one .9s linear;
}
.serve-list.two .serve-item:nth-child(2){
    animation:two 1.1s linear;
}
.serve-list.two .serve-item:nth-child(3){
    animation:three 1.3s linear;
}
.serve-list.two .serve-item:nth-child(4){
    animation:four 1.5s linear;
}
</style>
