<template>
    <div>
        <div class="content-save" id="cultureId" style="margin-bottom: 50px;">
            <div class="save-head motion" :class="scroll ? 'scroll-visible' : 'scroll-hidden'">
                <h1>企业文化</h1>
            </div>

            <div class="save-content">
                <div class="net-work clearfix" style="display:flex; justify-content: space-around;">
                    <div style="display:inlin-block;width: 30%" :class="scroll ? 'img-visible' : 'img-hidden'">
                        <img src="@/assets/images/culture-2.png" alt="" class="man"  >
                    </div>

                    <div class="content-alarm" style="display:inlin-block;width: 70%;" :class="scroll ? 'word-visible' : 'word-hidden'">
                        <div class="alarm-item alarm-item-china motion scroll-visible">
                            <div >
                                <p class="desc">恒春科技（大连）有限责任公司是一家经营物联网技术研发、技术服务、物联网应用服务、物联网设备制造、设备销售、网络设备制造、软件开发、销售、外包服务、计算机软硬件及辅助设备零售、技术的服务、开发、咨询、等业务领域的高新技术产业。本公司由资深的业务顾问、项目经理、高级工程师组成。</p>
                                <p class="desc">"脚踏实地， 务实笃行"，是我们一贯遵循的企业文化理念！讲究先做人，后做事，要求全体员工诚信高效、服务用户、团结进取、争创效益。我们的知识、思想和方法必须走在社会的前列，积极进取，建立以客户为中心的服务理念，不断提高企业对客户的服务意识。自尊自信自强，做企业信任；开拓进取创新，创企业辉煌。</p>
                            </div>
                        </div>
                    </div>
                    <div style="clear: both;"></div>
                </div>
            </div>
        </div>  
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    data(){
        return{
            scroll: false,
        }
    },
    mounted() {
        const that = this
        const cultureId = $("#cultureId");
        const top = cultureId.offset().top;
        window.addEventListener('scroll', function() {
            if (window.pageYOffset >= top || window.innerHeight >= top) {
                that.scroll = true
            } else {
                that.scroll = false
            }
        })
    },
}
</script>

<style scoped>
*{
  margin: 0;
  padding: 0;
}
.content-save .save-head {
    margin-bottom: 50px;
    text-align: center;
    transition: all 1s;
    margin-top: 50px;
}
.scroll-hidden{
    opacity: 0!important;
    transform: translateY(50px);
}
.scroll-visible {
    opacity: 1;
    transform: translateY(0);
}
.motion {
    transition: all .5s;
}
.man{
    width: 100%;
}
.img-hidden{
    transition: all 1s ease 0s, opacity 1.5s ease 0s;
    opacity: 0;
    transform: translateX(-65px);
}
.img-visible{
    transition: all 1s ease 0s, opacity 1.5s ease 0s;
    opacity: 1;
}
.word-hidden{
    transition: all 1s ease 0s, opacity 1.5s ease 0s;
    opacity: 0;
    transform: translateX(65px);
}
.word-visible{
    transition: all 1s ease 0s, opacity 1.5s ease 0s;
    opacity: 1;
}
.desc{
    font-size: 20px;
    color: #645c5c;
    line-height: 2;
    text-indent: 40px;
}
</style>