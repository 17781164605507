<template>
  <div id="container" @change="changeColor()">
    <div id="topAll">
      <div :class="this.scrollFlag ? 'top1':'top'">
      <div id="centerTop">
      <div class="top-logo">
        <img v-if="this.scrollFlag" src="@/assets/images/logo.png">
        <img v-else src="@/assets/images/logo.png">
      </div>
      <ul :class="this.scrollFlag ?'top-nav1':'top-nav'">
        <li><span @click="jumpTop()" :class="this.topDis==1 ?'active':''">首页</span></li>
        <li><span @click="jump(1)" :class="this.topDis==2 ?'active':''">服务范围</span></li>
        <!-- <li><span @click="jump(2)" :class="this.topDis==3 ?'active':''">产品展示</span></li> -->
        <li><span @click="jump(3)" :class="this.topDis==4 ?'active':''">企业文化</span></li>
        <li><span @click="jump(4)" :class="this.topDis==5 ?'active':''">服务支持</span></li>
        <li><span @click="jumpBottom()" :class="this.topDis==6 ?'active':''">联系我们</span></li>
      </ul>
      <button :class="this.scrollFlag ? 'top-btn1':'top-btn'" @click="jumpTop()">返回顶部</button>
    </div>
    </div>
  </div>
    <div id="clear"></div>
    <hzbanner></hzbanner>
    <div class="center">
      <hzmain id="page1"></hzmain>
    </div>
    <!-- <hzshow id="page2"></hzshow> -->
    <div class="center">
      <hzculture id="page3"></hzculture>
    </div>
    <hzserver id="page4"></hzserver>
    <hzcontact></hzcontact>
  </div>
</template>

<script>
import hzbanner from '@/components/hzbanner.vue'
import hzmain from '@/components/hzmain.vue'
// import hzshow from '@/components/hzshow.vue'
import hzserver from '@/components/hzserver.vue'
import hzculture from '@/components/hzculture.vue'
import hzcontact from '@/components/hzcontact.vue'

export default {
  name: 'Home',
  components: {
    hzbanner,
    hzmain,
    // hzshow,
    hzserver,
    hzculture,
    hzcontact
  },
  data(){
    return{
      scrollFlag:false,
      topDis:0,
      disTop:0,
    }
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll)
    this.changeColor()
  },
  methods:{
    changeColor(){
      if(this.disTop >= 0){
        this.topDis = 1
      }
      if(this.disTop >= 784){
        this.topDis = 2
      }
       if(this.disTop >= 2148){
        this.topDis = 3
      }
      if(this.disTop >= 2820){
        this.topDis = 4
      }
      if(this.disTop>= 3460){
        this.topDis = 5
      }
      if(this.disTop>= 3724){
        this.topDis = 6
      }
    },
    jumpTop(){
      window.scrollTo({'top':0,'behavior': 'smooth'})
    },
    //点击导航栏跳相对的部分
    jump(key){
        var PageId = document.querySelector('#page' + key)
        console.log(PageId.offsetTop)
        window.scrollTo({
        'top': PageId.offsetTop - 70,
        'behavior':'smooth'
        })
    },
    jumpBottom(){
      window.scrollTo({'top': document.documentElement.scrollHeight-document.documentElement.clientHeight,'behavior': 'smooth'});
      console.log(document.documentElement.scrollHeight-document.documentElement.clientHeight)
    },
    //向下滑动导航栏背景色改变
    handleScroll () {
      let _this=this;
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
       console.log(scrollTop)
       this.disTop = scrollTop
      if(scrollTop){
        _this.scrollFlag=true
      }else{
        _this.scrollFlag=false
      }
      this.changeColor()
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#container {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}

#topAll {
  width: 100%;
  height: 70px;
  position: fixed;
  z-index: 9999;
}

.top {
  transition: 500ms;
  width: 100%;
  height: 70px;
  background-color: transparent;
}

.top1 {
  transition: 500ms;
  width: 100%;
  height: 70px;
  background-color: white;
}

#centerTop {
  margin: 0 auto;
  width: 1100px;
  height: 70px;
}

.center {
  margin: 0 auto;
  width: 1100px;
}

.top-logo {
  width: 100px;
  height: 70px;
  float: left;
}

.top-logo img {
  width: 60px;
  height: 70px;
}

.top-nav {
  float: left;
  width: 700px;
  height: 70px;
  padding-left: 50px;
}

.top-nav1 {
  float: left;
  width: 700px;
  height: 70px;
  padding-left: 50px;
}

.top-nav li {
  float: left;
  list-style: none;
  width: 100px;
  height: 70px;
  line-height: 70px;
  text-align: center;
}

.top-nav1 li {
  float: left;
  list-style: none;
  width: 100px;
  height: 70px;
  line-height: 70px;
  text-align: center;
}

.top-nav li span {
  cursor:pointer;
  
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.top-nav1 li span {
  cursor:pointer;
  font-size: 18px;
  font-weight: bold;
}

.active {
  color: #0862ae;
}

.top-btn {
  float: left;
  width: 150px;
  height: 50px;
  background: white;
  margin-top: 10px;
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #0862ae;
  border: none;
  cursor: pointer;
}

.top-btn1 {
  float: left;
  width: 150px;
  height: 50px;
  background: #0862ae;
  margin-top: 10px;
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: white;
  border: none;
  cursor: pointer;
}

#clear {
  clear: both;
}
</style>
