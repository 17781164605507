<template>
    <div id="div">
        <div id="contact">
            <img src="@/assets/images/logo.png">
            <p>公司地址：辽宁省大连高新区技术产业园区希贤街29号的弘泰大厦A座六层部分区域（房间号602-2）</p>
            <p>Copyright&copy;恒春科技（大连）有限责任公司</p>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>

<style scoped>
img{
  width: 100px;
  height: 80px;
}
*{
  margin: 0;
  padding: 0;
}
#div{
    width: 100%;
    height: 300px;
    background: #05355c;
}
#contact{
    margin: 0 auto;
    width: 1100px;
    height: 150px;
    text-align: center;
    color: white;
    font-size: 18px;
    
    padding-top: 34px;
}
#contact p{
    margin-top: 8px;
}
</style>